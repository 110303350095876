import { ProcessService} from '@/modules/process-editor/process-editor-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';

// action types
export const DO_UPDATE = 'update';
export const DO_CREATE = 'create';
export const DO_FIND_ACTIONS = 'actions';
export const DO_REMOVE = 'remove';

// mutation types
export const SET_ACTIONS = 'setActions';

const state = {
    actions: []
};

const getters = {
    actions: (state) => state.actions,
};

const mutations = {
    [SET_ACTIONS](state, actions) {
        state.actions = actions;
    },
}

const actions = {
    [DO_CREATE](context, step) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'processStage/create', { root: true });
            ProcessService.createStep(step)
                .then((data) => {
                    Message.success(i18n.t('GENERAL.CREATED_SUCCESS'));
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'processStage/create', { root: true });
                });
        });
    },
    [DO_FIND_ACTIONS](context, step) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'processStage/getActions', { root: true });
            setTimeout(() => {
                ProcessService.fetchActions()
                .then((data) => {
                    context.commit(SET_ACTIONS, data.data);
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'processStage/getActions', { root: true });
                });
            }, 200)
        });
    },
    [DO_REMOVE](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'processStage/remove', { root: true });
            ProcessService.removeStep(id)
                .then(() => {
                    Message.success(i18n.t('GENERAL.REMOVE_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'processStage/remove', { root: true });
                });
        });
    },
    [DO_UPDATE](context, { id, payload }) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'processStage/update', { root: true });
            ProcessService.updateStep(id, payload)
                .then(() => {
                    Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'processStage/update', { root: true });
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
