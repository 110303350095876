import processEditorFormStore from '@/modules/process-editor/store/process-editor-form-store';
import processEditorListStore from '@/modules/process-editor/store/process-editor-list-store';
import processStageFomStore from '@/modules/process-editor/store/process-stage-form-store';
import processStageListStore from '@/modules/process-editor/store/process-stage-list-store';
import { ProcessEditorPermissions } from '@/modules/process-editor/process-editor-permissions';

const getters = {
    processEditorPermissions : (state, getters, rootState, rootGetters) => {
        const currentUser = rootGetters['auth/currentUser'];
        return {
            hasPermissionToEdit: new ProcessEditorPermissions(currentUser).edit,
            hasPermissionToDestroy: new ProcessEditorPermissions(currentUser).destroy,
            hasPermissionToViewSteps: new ProcessEditorPermissions(currentUser).readSteps,
            hasPermissionToEditSteps: new ProcessEditorPermissions(currentUser).editSteps,
            hasPermissionToDestroyStep: new ProcessEditorPermissions(currentUser).destroySteps,
        }
    }
};

export default {
    namespaced: true,
    modules: {
        processForm: processEditorFormStore,
        processList: processEditorListStore,
        stageForm: processStageFomStore,
        stageList: processStageListStore,
    },
    getters,
};
