import PermissionChecker from '@/security/permission-checker';
import Permissions from '@/security/permissions';

export class ProcessEditorPermissions {
    constructor(currentUser) {
        const permissionChecker = new PermissionChecker(
            currentUser,
        );

        this.read = permissionChecker.match(
            Permissions.values.processEditorRead,
        );
        this.create = permissionChecker.match(
            Permissions.values.processEditorCreate,
        );
        this.edit = permissionChecker.match(
            Permissions.values.processEditorEdit,
        );
        this.destroy = permissionChecker.match(
            Permissions.values.processEditorDestroy,
        );
        this.readSteps = permissionChecker.match(
            Permissions.values.processEditorStepsRead,
        );
        this.createSteps = permissionChecker.match(
            Permissions.values.processEditorStepsCreate,
        );
        this.editSteps = permissionChecker.match(
            Permissions.values.processEditorStepsEdit,
        );
        this.destroySteps = permissionChecker.match(
            Permissions.values.processEditorStepsDestroy,
        );
    }
}
