import { ProcessService} from '@/modules/process-editor/process-editor-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';

// action types
export const DO_FETCH = 'doFetch';
export const DO_UPDATE_LIST = 'doUpdate';
export const DO_PUBLISH_WORKFLOW = 'publishWorkflow';

// mutation types
export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';

const state = {
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
};

const getters = {
    rows: (state) => {
        return state.rows.sort((a, b) => {
            return a.order - b.order;
        });
    },
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
};

const actions = {
    [DO_FETCH](context, {payload, id}) {
        context.commit('shared/activateLoading', 'processEditor/getSteps', { root: true });
        payload.query = `workflow_id eq ${id} and ` + payload.query;
        return new Promise((resolve, reject) => {
            const filters = { params: { ...payload, pagination: true } };
            ProcessService.listSteps(filters)
                .then((data) => {
                    context.commit(FETCH_SUCCESS, {
                        rows: data.data,
                        total: data.pagination.total,
                    });
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'processEditor/getSteps', { root: true });
                });
        });
    },
    [DO_UPDATE_LIST](context, processes) {
        context.commit('shared/activateLoading', 'processEditor/updateSteps', { root: true });
        return new Promise((resolve, reject) => {
            ProcessService.updateList(processes)
                .then((data) => {
                    Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'processEditor/updateSteps', { root: true });
                });
        });
    },
    [DO_PUBLISH_WORKFLOW](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'processStage/publishWorkflow', { root: true });
            ProcessService.publishWorkflow(id)
                .then(() => {
                    Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'processStage/publishWorkflow', { root: true });
                });
        });
    },
};

const mutations = {
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
