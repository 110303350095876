import { ProcessService} from '@/modules/process-editor/process-editor-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';

// action types
export const DO_CREATE = 'create';
export const DO_REMOVE = 'remove';
export const DO_UPDATE = 'update';
export const DO_FIND = 'find';
export const DO_CLEAR = 'clear';

// mutation types
export const SET_PROCESS = 'setSelected';
export const RESET = 'reset';

const state = {
    record: {},
};

const getters = {
    record: (state) => state.record,
};

const actions = {
    [DO_FIND](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'processEditor/find', { root: true });
                ProcessService.find(id)
                .then((data) => {
                    context.commit(SET_PROCESS, data.data);
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'processEditor/find', { root: true });
                });
        });
    },
    [DO_CLEAR](context) {
        context.commit(RESET);
    },
    [DO_CREATE](context, process) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'processEditor/create', { root: true });
            ProcessService.create(process)
                .then((data) => {
                    context.commit(SET_PROCESS, data.data);
                    Message.success(i18n.t('GENERAL.CREATED_SUCCESS'));
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'processEditor/create', { root: true });
                });
        });
    },
    [DO_REMOVE](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'processEditor/create', { root: true });
            ProcessService.remove(id)
                .then((data) => {
                    Message.success(i18n.t('GENERAL.REMOVE_SUCCESS'));
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'processEditor/create', { root: true });
                });
        });
    },
    [DO_UPDATE](context, { id, payload }) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'processEditor/update', { root: true });
            ProcessService.update(id, payload)
                .then(() => {
                    Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'processEditor/update', { root: true });
                });
        });
    },
}

const mutations = {
    [SET_PROCESS](state, record) {
        state.record = record;
    },
    [RESET](state) {
        state.record = {};
    }
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
