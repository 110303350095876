import ApiService from '@/shared/services/api.service';

export class ProcessService {
    static listProcesses(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('workflows', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static updateList(params) {
        return new Promise((resolve, reject) => {
            ApiService.post('workflow-steps/bulk-update', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post('/workflows?echo=true', data )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }
    static async remove(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/workflows/${id}`)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }
    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/workflows/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static find(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/workflows/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }
    static updateSteps(list) {
        return new Promise((resolve, reject) => {
            resolve()
        });
    }
    static fetchActions() {
        return new Promise((resolve, reject) => {
            ApiService.get('/workflow-actions?limit=100')
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static actionsType(list) {
        return new Promise((resolve, reject) => {
            const actionsType = [
                { id: 1, value: 'manual', text: 'manual' },
                { id: 2, value: 'automat', text: 'automat' },
            ]
            resolve(actionsType)
        });
    }
    static extraDocumentVisibilityOptions() {
        return new Promise((resolve, reject) => {
            const actionsType = [
                { id: 1, value: 'private', text: 'private' },
                { id: 2, value: 'public', text: 'public' },
            ]
            resolve(actionsType)
        });
    }
    static createStep(data) {
        return new Promise((resolve, reject) => {
            ApiService.post('/workflow-steps', data)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }
    static listSteps(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/workflow-steps', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static async removeStep(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/workflow-steps/${id}`)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }
    static updateStep(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/workflow-steps/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static publishWorkflow(id) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/workflows/publish/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }
    static collectMetadata(id) {
        return new Promise((resolve, reject) => {
            ApiService.query(`workflows/collect-metadata/${id}`, { params: { last_action: 'generateDocument' } } )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        })
    }
}
